import Image from "next/image";
import PatternOrange from "public/images/pattern-orange.png";

import {
    BackgroundImage,
    Container,
    Description,
    Title,
    Wrapper
} from "components/homePage/about/About.styles";

export function About(): JSX.Element {
    return (
        <Container>
            <BackgroundImage>
                <Image
                    src={PatternOrange}
                    alt="pattern-orange"
                    objectFit="contain"
                />
            </BackgroundImage>
            <Wrapper>
                <Title>
                    Lets tok <span>history</span>
                </Title>
                <Description>
                    <p>
                        Heel Nederland en Vlaanderen voorzien van de
                        aller-verste kip; we hebben het er maar druk mee. Sinds
                        1993 zijn wij actief in het ambachtelijk fileren van kip
                        voor de zakelijke markt. Nu stellen wij deze prachtige
                        ambacht beschikbaar aan de consument met Mr. Fillet. De
                        schoonste, meest verse en lekkerste kip, zorgeloos en
                        kosteloos bezorgd tot aan jouw deur. Dat is wat wij
                        doen, iedere dag weer.
                    </p>
                    {/* <Button
                        href="/"
                        size="simple"
                        icon={{ icon: ARROW_RIGHT }}
                        buttonType="link"
                        reversedOrder
                    >
                        Meer over ons
                    </Button> */}
                </Description>
            </Wrapper>
        </Container>
    );
}
