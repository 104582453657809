import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.section`
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;
    padding: 70px 24px;
    position: relative;

    ${media.lgUp} {
        overflow: revert;
        padding: 70px 0;
    }
`;

export const BackgroundImage = styled.div`
    height: 738px;
    left: -5%;
    position: absolute;
    top: -5%;
    width: 1335.64px;

    ${media.smUp} {
        width: 1441.67px;
    }
`;

export const Wrapper = styled.div`
    background-color: var(--color-beige-40);
    padding: 24px;
    position: relative;

    ${media.lgUp} {
        max-width: 50%;
        padding: 40px;
    }
`;

export const Title = styled.h2`
    color: var(--color-brown-50);
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 16px;
    max-width: 224px;

    > span {
        color: var(--color-orange-50);
        font-size: 34px;
        line-height: 48px;
    }

    ${media.lgUp} {
        font-size: 56px;
        line-height: 64px;
        margin-bottom: 32px;
        max-width: 392px;

        > span {
            font-size: 56px;
            line-height: 64px;
        }
    }
`;

export const Description = styled.div`
    padding-left: 24px;

    > p {
        margin-bottom: 10px;
    }

    ${media.lgUp} {
        padding-left: 64px;
    }
`;
